<template>
  <el-dialog title="Change Cleaner Status" :visible.sync="setShow" width="30%">
    <el-form :model="form" label-position="top">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Select new status"
            prop="status"
            :rules="validateFigure()"
          >
            <el-radio-group v-model="form.status">
              <el-radio :label="'active'">Active</el-radio>
              <el-radio :label="'inactive'">Inactive</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="setShow = false">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="cleaner.status === form.status"
        @click="change"
        >Change</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cleaners from "@/requests/logistics/directory/cleaners";

export default {
  name: "CleanerStatus",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    cleaner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      changing: false,
      form: {
        status: "",
      },
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    enableDelete() {
      return this.form.email === this.customer.email?.toLowerCase();
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.status = this.cleaner.status;
      }
    },
  },
  methods: {
    change() {
      this.changing = true;

      const payload = {
        cleaner_id: this.cleaner.id,
        cleaner_status: this.form.status,
      };

      cleaners
        .update(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.close();
          } else {
            this.changing = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    close() {
      this.setShow = false;
      this.changing = false;
      this.form.status = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
